import styled from '@emotion/styled'
import { BgCoinContainer } from 'assets/images';
import { mixins } from 'theme';

export const CoinContainer = styled.div`
  z-index: 2;
  position: relative;
  width: 290px;
  height: 57px;
  background: url(${BgCoinContainer});
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
  font-size: 20px;
  color: white;
  font-family: Roboto;
  font-weight: 700;
  .total-earning {
    width: 87%;
    margin-right: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${mixins.mobileWidth}px) {
    .total-earning {
      padding-left: 20px;
      span {
        font-size: 20px;
      }
    }
  }
`;